<template>
  <section
    :class="[`no-${index}`]"
  >
    <header>
      <h3>{{ item.storeName }}</h3>
      <dl v-if="index <= 3">
        <dt>Peringkat</dt>
        <dd>No{{ index }}</dd>
      </dl>
    </header>
    <div class="body">
      <div
        v-if="item.title.length > 0"
        class="body-title"
      >
        <div class="body-title-text">
          {{ item.title }}
        </div>
      </div>
      <div class="body-summary">
        <div class="img">
          <img
            alt=""
            :src="imgPath(item.assertName)"
          >
        </div>
        <table>
          <tbody>
            <tr v-if="item.rating">
              <th>Evaluasi</th>
              <td class="rating">
                <span class="rating-number">{{ item.rating }}</span>
                <span class="rating-icons">
                  <SvgIcon
                    v-for="(icon,idx) in ratingArray(item)"
                    :key="idx"
                    type="mdi"
                    class="icon"
                    :path="icon"
                    size="18"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <!-- 対応エリア -->
              <th>Area yang dicakup.</th>
              <td>
                {{ item.area.length ? item.area.join(' / ') : 'Pilih LPK yang akan diperiksa' }}
              </td>
            </tr>
            <tr>
              <!-- 授業形式 -->
              <th>Format kelas</th>
              <td>
                {{ item.type.length ? item.type.join(' / ') : 'Pilih LPK yang akan diperiksa' }}
              </td>
            </tr>
            <tr>
              <!-- 対象学年 -->
              <th>Target kelompok usia</th>
              <td>
                {{ item.target.length ? item.target.join(' / ') : 'Pilih LPK yang akan diperiksa' }}
              </td>
            </tr>
            <tr>
              <!-- 料金 -->
              <th>Harga</th>
              <td>
                <template v-if="item.price.length">
                  <template
                    v-for="(price) in item.price"
                    :key="price"
                  >
                    {{ price }}
                    <br v-if="index < item.price.length - 1">
                  </template>
                </template>
                <template v-else>
                  Pilih LPK yang akan diperiksa
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="item.pointText.length >0"
        class="body-point"
      >
        <h4>Rekomendasi & Keunggulan</h4>
        <ol>
          <li
            v-for="text in item.pointText"
            :key="text"
          >
            <em>{{ text.split('||')[0] }}</em><br>
            {{ text.split('||')[1] }}
          </li>
        </ol>
      </div>
      <div class="body-buttons">
        <a
          class="button"
          target="_blank"
          :href="formLink"
        >
          <SvgIcon
            type="mdi"
            class="icon"
            :path="mdiFileDocumentEditOutline"
            size="24"
          />
          Meminta informasi di web
        </a>
        <p v-if="item.link">
          <a
            :href="item.link"
            target="_blank"
          >
            tautan eksternal
            <SvgIcon
              type="mdi"
              class="icon"
              :path="mdiChevronRight"
              size="24"
            />
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiStar, mdiStarHalfFull, mdiStarOutline, mdiChevronRight, mdiFileDocumentEditOutline } from '@mdi/js'
import { inject, onMounted } from 'vue'
import { Emitter } from 'mitt'
import {formLink} from "@/constants/Items"
import { Item } from '@/type/index'

defineProps<{
  item: Item,
  index: number
}>();
const emitter = inject('emitter') as Emitter<{
  scrollToItem: string;
}>
const imgPath = (name:string)=>{
  return require(`@/assets/img/store/${name}/img.png`);
};
onMounted(() => {
  emitter.on('scrollToItem', (className:string) => {
    const element = document.querySelector(`.${className}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  })
})

const ratingArray = (item:Item) => {
  const rating = item.rating;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  const icons = [];
  for (let i = 0; i < fullStars; i++) {
    icons.push(mdiStar);
  }
  if (hasHalfStar) {
    icons.push(mdiStarHalfFull);
  }
  for (let i = 0; i < emptyStars; i++) {
    icons.push(mdiStarOutline);
  }
  return icons;
}

</script>

<style scoped lang="scss">
header {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  min-height: 44px;
  background-color: silver;
  .no-1 & {
    background: #e2b923;
  }
  .no-2 & {
    background: #b2c4d5;
  }
  .no-3 & {
    background: #d3a46c;
  }
  dl {
    border: 1px solid #E82615;
    width: 88px;
    font-size: 10px;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;
  }
  dt {
    background-color: #E82615;
  }
  dd {
    background-color: #fff;
    color: #E82615;
  }
}
.body{
  background-color: #fff;
}
.button{
  border: solid 3px #27aeff;
  background-color: #27aeff;
  box-shadow: 0 4px #3086bb;
  color: #fff;
  height: 48px;
  font-size: 16px;
  max-width: 272px;
  line-height: 1.3;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  &:active{
    box-shadow: 0 0 #24427b;
    transform: translateY(4px);
  }
}
.body-title {
  display: flex;
  align-items: flex-start;
  padding: 8px 12px 0;
  gap: 8px;
}
.body-title-text{
  border-bottom: #ccc solid 2px;
  padding-bottom: 8px;
  width: 100%;
}
.body-summary {
  display: flex;
  display: flex;
  padding: 8px 12px;
  font-size: 12px;
  text-align: left;
  line-height: 1.2;
  gap: 8px;
  .img {
    width: 80px;
    min-width: 80px;
  }
}
.rating{
  display: flex;
  align-items: center;
  gap:2px;
  .rating-number{
    color: #E82615;
    font-weight: 700;
  }
  .rating-icons{
    display: flex;
    align-items: center;

  }
  .icon{
    color: #ffd001;
    margin: 0 -1px;
  }
}
.body-point{
  margin: 0 12px;
  padding: 16px;
  background-color: #fff8d9;
  font-size: 14px;
  border-radius: 8px;
  h4 {
    font-size: 16px;
  }
  ol {
    margin: 12px 0 0;
    padding: 0;
  }
  li {
    position: relative;
    padding: 3px 0 3px 22px;
    list-style-type: none;
    list-style-position: inside;
    counter-increment: cnt;
    &:before{
      position: absolute;
      left: 0;
      content: counter(cnt);
      color: #fff;
      font-weight: 700;
      text-align: center;
      height: 16px;
      width: 16px;
      font-size: 10px;
      background-color: #e82615;
      border-radius: 50%;
      line-height: 1.5;
      top: 5px;
    }
  }
  em{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
  }
}
.body-buttons{
  padding: 12px 12px 16px;
  .button{
    margin: auto;
  }
  a{
    text-decoration: none;
  }
  p{
    text-align: right;
    line-height: 1.2;
    margin-top: 12px;
    font-size: 13px;
    a {
      display: inline-flex;
      align-items: center;
    }
  }
  .icon {
    color:#81be28;
    margin: -2px -4px 0px;
  }
  .button .icon{
    color: #fff;
    margin: 0 6px 0 0;
  }
}
table, th,td{
  display: block;
}
</style>
