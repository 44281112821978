export const formLink = "https://docs.google.com/forms/d/e/1FAIpQLSexeiSGLUCZuzyz9BDIzEcNmf0OLfJQPxQvP5JHsS3pV3okzw/viewform"
export const items = [
  {
    assertName: "onodera_run",
    storeName: "onodera ran",
    title : "",
    area: ["Jakarta"],
    type: ["Tatap muka (di asrama)"],
    target: ["18-25 tahun"],
    price: ["Bebas biaya (terbatas untuk usia 18-25 tahun)"],
    pointText: [
      "Pendidikan gratis dan berkualitas tinggi untuk anak muda berusia 18-25 tahun||Kami menyediakan lingkungan di mana anak muda yang akan membuka jalan menuju masa depan dapat belajar tanpa mengkhawatirkan biaya.",
      "Mengembangkan kurikulum yang unik dengan bahan ajar yang orisinil||Kurikulum kami yang unik menggunakan bahan ajar orisinil yang dikembangkan secara khusus untuk tujuan tersebut, sehingga memungkinkan siswa untuk memperoleh keterampilan praktis.",
      "Perusahaan pengenalan sumber daya manusia yang berspesialisasi dalam keterampilan khusus||kekuatan di bidang asuhan keperawatan Kami telah menghasilkan lebih dari 2.600 kandidat yang sukses dalam tes keterampilan khusus. Kekuatan khusus di bidang asuhan keperawatan, membantu orang untuk mendapatkan pekerjaan yang sukses di Jepang.",
    ],
    // link: "https://www.google.com/2"
    // point: [
    //   "P1",
    //   "P2",
    //   "P3"
    // ],
    // price1: "1111",
    // price2: "1111",
    // price3: "1111",
    // staff: ["staff1","staff2","staff2"],
  },
  {
    assertName: "trimitra_matahari",
    storeName: "Trimitra Matahari Mandiri",
    title : "",
    area: ["Bekasi"],
    type: ["Tatap muka"],
    target: ["18-30 tahun"],
    price: ["Biaya Pilih LPK yang akan dicek"],
    pointText: [
      "Pengalaman pendidikan yang otentik dengan belajar langsung dari guru-guru Jepang||Tidak hanya kemampuan bahasa, tetapi juga tata krama dan sikap orang Jepang di tempat kerja.",
      "Dukungan penuh dan awal yang aman||Semua biaya asrama dan pendidikan bahasa Jepang gratis sampai Anda lulus wawancara. Anda dapat berkonsentrasi pada studi Anda tanpa mengkhawatirkan beban keuangan.",
      "Dukungan untuk sukses di Jepang||Tingkat ketidakhadiran lulusan yang mendapatkan pekerjaan di Jepang adalah 0%. Kami memberi Anda masa depan yang aman di mana Anda dapat bekerja di perusahaan yang dapat diandalkan dengan ketenangan pikiran.",
    ],
    // link: "https://www.google.com/2"
  },
  {
    assertName: "hamaren",
    storeName: "LPK Hamaren",
    title : "",
    area: ["Bekasi", "Subang", "Indramayu", "Kudus", "Kudus", "Bali", "Lebak", "Sidoarjo", "dll"],
    type: ["Kehidupan asrama"],
    target: [],
    price: ["Pilih LPK yang akan diperiksa"],
    pointText: [
      "Sekolah teladan kelas atas yang dikunjungi oleh pemerintah Jepang.||Fasilitas canggih untuk perawatan dan pemeliharaan. LPK menyediakan lingkungan di mana Anda dapat bekerja segera setelah Anda pergi ke Jepang.",
      "Pilihlah pekerjaan yang tepat untuk Anda dari berbagai||peluang kerja Ada banyak pekerjaan yang tersedia di Jepang, sehingga Anda dapat dengan bebas memilih pekerjaan yang benar-benar Anda inginkan.",
      "Anda juga dapat menerima pendidikan universitas di Indonesia sambil bekerja di Jepang||Anda dapat menerima pendidikan jarak jauh di universitas di Indonesia secara paralel dengan pekerjaan Anda di Jepang. Anda dapat mewujudkan masa depan di mana Anda tidak perlu melepaskan karir atau studi Anda.",
    ],
    // link: "https://www.google.com/2"
  },
  {
    assertName: "pt_minori",
    storeName: "PT MINORI",
    title : "",
    area: ["Bekasi", "Jawa"],
    type: ["Tatap muka"],
    target: ["minimum 18 hingga 27 tahun"],
    price: ["Pilih LPK yang akan diperiksa"],

    pointText: [
      "Terbesar di Indonesia, 1999 siswa berangkat ke Jepang!||Kami adalah Organisasi Pengirim Magang Jepang (SO) terbesar di Indonesia, yang telah mengirimkan 1999 peserta ke Jepang setiap tahunnya.",
      "Dukungan penuh di seluruh Jepang||Kami memiliki jaringan cabang di Tokyo, Nagoya, Osaka, dan Okayama untuk memberikan dukungan penuh kepada peserta magang di Jepang.",
      "Serahkan persiapan keberangkatan kepada kami! Tidak perlu khawatir tentang biaya.||Anda dapat berangkat ke Jepang dengan tenang, tanpa pembayaran di muka dan proses pelatihan persiapan yang berlangsung selama rata-rata lima hingga tujuh bulan dengan semua fasilitas yang tersedia.",
    ],
    // link: "https://www.google.com/2"
  },
  {
    assertName: "bangkit_indonesia",
    storeName: "LPK Bangkit Indonesia",
    title : "",
    area: ["Jakarta"],
    type: ["Tatap muka"],
    target: ["20-27 tahun"],
    price: ["Pilih LPK yang akan diperiksa"],
    pointText: [
      "Sebanyak 2.567 orang||telah diberangkatkan ke Jepang dan memiliki masa depan baru di tangan mereka Kami telah memberangkatkan 2.567 orang ke Jepang dan membantu mereka mewujudkan impian mereka.",
      "Organisasi pengirim terbaik kedua di Indonesia||Peringkat kedua sebagai 'Organisasi Pengirim Terbaik di Indonesia'. Ini adalah organisasi yang dipilih dengan kepercayaan dan rekam jejak yang terbukti.",
    ],
    // link: "https://www.google.com/2"
  },

  {
    assertName: "coop",
    storeName: "lpk coop",
    title : "",
    area: ["Jakarta"],
    type: ["Tatap muka"],
    target: ["19-27 tahun"],
    price: ["Pilih LPK yang akan diperiksa"],
    pointText: [
      "Lebih dari 2.800 orang telah meraih kesuksesan di Jepang||Lebih dari 2.800 peserta pelatihan telah mengembangkan keterampilan dan pengalaman mereka di perusahaan-perusahaan terkemuka di Jepang.",
      "THE ENTREPRENEUR - Magang untuk wirausahawan mandiri Program magang||THE ENTREPRENEUR dirancang sebagai langkah pertama menuju aspirasi masa depan para peserta untuk menjadi wirausahawan mandiri.",
    ],
    // link: "https://www.google.com/2"
  },


  {
    assertName: "yutaka",
    storeName: "LPCK YUTAKA",
    title : "",
    area: [],
    type: ["Tatap muka"],
    target: ["19-27 tahun"],
    price: ["Pilih LPK yang akan diperiksa"],
    pointText: [
      "Tingkat ketidakhadiran kurang dari 1%.||Kami menyediakan tempat kerja yang berkualitas di mana Anda dapat bekerja dengan tenang.",
      "Tidak ada biaya perjalanan ke Jepang.||Perusahaan menyediakan beasiswa dan mendukung impian Anda.",
      "Ketenangan pikiran setelah kembali ke Indonesia.||Kami mendukung Anda dalam pengembangan karir Anda.",
    ],
    // link: "https://www.google.com/2"
  },

  {
    assertName: "anugerah",
    storeName: "LPK PT INDONESIA NIPPON ANUGERAH",
    title : "",
    area: [],
    type: ["Tatap muka"],
    target: ["19-27 tahun"],
    price: ["Pilih LPK yang akan diperiksa"],
    pointText: [
      "Pelatihan selama satu bulan di perusahaan Indonesia sebelum masuk ke Jepang. ||Siswa akan dipersiapkan untuk segera bekerja di pasar lokal.",
      "Biaya pendidikan di Indonesia tanpa biaya, bekerja sama dengan bank. ||Pembayaran fleksibel setelah tiba di Jepang.",
      "Sebanyak lebih dari 1.300 orang telah dilayani sejauh ini.||Sebanyak 1.358 siswa telah berhasil mendapatkan pekerjaan di Jepang, dan kami memiliki rekam jejak yang baik dengan berbagai peluang kerja.",
    ],
    // link: "https://www.google.com/2"
  },
]